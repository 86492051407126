import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService,  private router: Router) { }

  canActivate(): boolean {
    if (this.authService.isLoggedIn()) { // Utilisez une méthode appropriée de votre service d'authentification
      return true; // L'utilisateur est authentifié, il peut accéder à la route
    } else {
      this.router.navigate(['auth']); // Redirigez l'utilisateur vers la page de connexion
      return false; // L'utilisateur n'est pas autorisé à accéder à la route
    }
  }
}
