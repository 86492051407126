import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LocalStorageService } from '../services/base/localStorage.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}


  private handleAuthError(error: HttpErrorResponse): void {
    if (error.status === 401 || error.status === 403) {
      const token = this.localStorageService.getToken();
      if (token) {
        console.error('Erreur d\'authentification. Déconnexion en cours.');
        this.localStorageService.destroyToken();
        this.router.navigate(['/auth']);
      }
    } else if (error.status === 400) {
      console.error('Erreur d\'authentification. Déconnexion en cours.');
      if (this.localStorageService.getToken()) {
        this.localStorageService.destroyToken();
      }
      console.log(error);
    } else {
      console.log(error);
    }
  }
  

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.localStorageService.getToken();

    const authReq = authToken ? req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authToken}`)
    }) : req;

    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        this.handleAuthError(error);  // Gère les erreurs d'authentification
        return throwError(error);  // Propagation de l'erreur pour un traitement ultérieur
      })
    );
  }
}

